<template>
  <body>

  <div class="knfgsr_wrapper">

    <!--   Header   -->
    <header class="knfgsr_header">
      <div class="knfgsr_header__container knfgsr__container">
        <div class="knfgsr_header__body">
          <a class="knfgsr_header__logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="#fff" d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
            </svg>
          </a>
          <nav class="knfgsr_header__menu">
            <ul class="knfgsr_header__list">
              <li><a href="javascript:document.getElementById('about').scrollIntoView();" class="knfgsr_header__link">{{ t('aboutUs') }}</a></li>
              <li><a href="javascript:document.getElementById('stat').scrollIntoView();" class="knfgsr_header__link">{{ t('popularity') }}</a></li>
              <li><a href="javascript:document.getElementById('faq').scrollIntoView();" class="knfgsr_header__link">{{ t('faq') }}</a></li>
            </ul>
            <a @click="showPopup" class="knfgsr_header__login">{{ t('loginVia') }}
              <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="16px"
                   height="16px">
                <path
                    d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 17.251208 3.6323415 19.350068 4.7109375 21.150391 L 3.1074219 27 L 9.0820312 25.431641 C 10.829354 26.425062 12.84649 27 15 27 C 21.627 27 27 21.627 27 15 C 27 8.373 21.627 3 15 3 z M 10.892578 9.4023438 C 11.087578 9.4023438 11.287937 9.4011562 11.460938 9.4101562 C 11.674938 9.4151563 11.907859 9.4308281 12.130859 9.9238281 C 12.395859 10.509828 12.972875 11.979906 13.046875 12.128906 C 13.120875 12.277906 13.173313 12.453437 13.070312 12.648438 C 12.972312 12.848437 12.921344 12.969484 12.777344 13.146484 C 12.628344 13.318484 12.465078 13.532109 12.330078 13.662109 C 12.181078 13.811109 12.027219 13.974484 12.199219 14.271484 C 12.371219 14.568484 12.968563 15.542125 13.851562 16.328125 C 14.986562 17.342125 15.944188 17.653734 16.242188 17.802734 C 16.540187 17.951734 16.712766 17.928516 16.884766 17.728516 C 17.061766 17.533516 17.628125 16.864406 17.828125 16.566406 C 18.023125 16.268406 18.222188 16.319969 18.492188 16.417969 C 18.766188 16.515969 20.227391 17.235766 20.525391 17.384766 C 20.823391 17.533766 21.01875 17.607516 21.09375 17.728516 C 21.17075 17.853516 21.170828 18.448578 20.923828 19.142578 C 20.676828 19.835578 19.463922 20.505734 18.919922 20.552734 C 18.370922 20.603734 17.858562 20.7995 15.351562 19.8125 C 12.327563 18.6215 10.420484 15.524219 10.271484 15.324219 C 10.122484 15.129219 9.0605469 13.713906 9.0605469 12.253906 C 9.0605469 10.788906 9.8286563 10.071437 10.097656 9.7734375 C 10.371656 9.4754375 10.692578 9.4023438 10.892578 9.4023438 z"/>
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </header>

    <!--   Main   -->
    <main class="knfgsr_main" v-if="localSettings">

      <!--   Participants   -->
      <section class="knfgsr_participants">
        <div class="knfgsr_participants__container knfgsr__container">
          <h2 class="knfgsr_title knfgsr_participants__title">{{ t('chooseParticipantToVote') }}</h2>
          <div class="knfgsr_participants__wrap">

            <div class="knfgsr_participants__list">

              <!--   Contestant   -->
              <div class="knfgsr_contestant knfgsr_participants__contestant">
                <div class="knfgsr_contestant__img-cnt">
                  <picture>
                    <source :srcset="localSettings.image_1" type="image/webp">
                    <img class="knfgsr_contestant__image" :src="localSettings.image_1" alt></picture>
                </div>
                <p class="knfgsr_contestant__name">{{ localSettings.name_1 }}</p>
                <p class="knfgsr_contestant__info"><span class="knfgsr_contestant__count">{{ localSettings.votes_1 }}</span>
                  {{ t('votes') }}</p>
                <a @click="showPopup()" class="knfgsr_contestant__btn knfgsr_btn">{{ t('vote') }}</a>
              </div>

              <!--   Contestant   -->
              <div class="knfgsr_contestant">
                <div class="knfgsr_contestant__img-cnt">
                  <picture>
                    <source :srcset="localSettings.image_2" type="image/webp">
                    <img class="knfgsr_contestant__image" :src="localSettings.image_2" alt></picture>
                </div>
                <p class="knfgsr_contestant__name">{{ localSettings.name_2 }}</p>
                <p class="knfgsr_contestant__info"><span class="knfgsr_contestant__count">{{ localSettings.votes_2 }}</span>
                  {{ t('votes') }}</p>
                <a @click="showPopup()" class="knfgsr_contestant__btn knfgsr_btn">{{ t('vote') }}</a>
              </div>


            </div>

          </div>
        </div>
      </section>

      <!--   Stat   -->
      <section class="knfgsr_stat" id="stat">
        <div class="knfgsr_stat__container knfgsr__container">
          <h2 class="knfgsr_title knfgsr_stat__title">{{ t('bePopularInSocialMedia') }}</h2>
          <div class="knfgsr_stat__wrap">
            <div class="knfgsr_stat__desc">
              <p class="knfgsr_stat__info">103 219</p>
              <span class="knfgsr_stat__text">{{ t('votings') }}</span>
            </div>
            <div class="knfgsr_stat__desc">
              <p class="knfgsr_stat__info">213 810</p>
              <span class="knfgsr_stat__text">{{ t('users') }}</span>
            </div>
            <div class="knfgsr_stat__desc">
              <p class="knfgsr_stat__info">100</p>
              <span class="knfgsr_stat__text">{{ t('prizes') }}</span>
            </div>
          </div>
        </div>
      </section>

      <!--   About   -->
      <section class="knfgsr_about" id="about">
        <div class="knfgsr_about__container knfgsr__container">
          <div class="knfgsr_about__body">
            <h2 class="knfgsr_title knfgsr_about__title">{{ t('aboutUs') }}</h2>
            <div class="knfgsr_about__wrap">
              <div class="knfgsr_about__item">
                <picture>
                  <source srcset="/assets/fonts/PPudb5NO46u3.svg" type="image/webp">
                  <img class="knfgsr_about__image" src="/assets/fonts/PPudb5NO46u3.svg" alt></picture>
                <p class="knfgsr_about__desc">{{ t('whoWeAre') }}</p>
                <p class="knfgsr_about__text">{{ t('whoWeAreText') }}</p>
              </div>
              <div class="knfgsr_about__item">
                <picture>
                  <source srcset="/assets/fonts/8ABisBLxZB5H.svg" type="image/webp">
                  <img class="knfgsr_about__image" src="/assets/fonts/8ABisBLxZB5H.svg" alt></picture>
                <p class="knfgsr_about__desc">{{ t('promotion') }}</p>
                <p class="knfgsr_about__text">{{ t('promotionText') }}</p>
              </div>
              <div class="knfgsr_about__item">
                <picture>
                  <source srcset="/assets/fonts/4uyF6CMrXquo.svg" type="image/webp">
                  <img class="/assets/images/present.svg" alt></picture>
                <p class="knfgsr_about__desc">{{ t('prizes') }}</p>
                <p class="knfgsr_about__text">{{ t('prizesText') }}</p>
              </div>
            </div>
            <div class="knfgsr_about__button">
              <a @click="showPopup()" class="knfgsr_about__btn knfgsr_btn">{{ t('loginVia') }}
                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="16px"
                     height="16px">
                  <path
                      d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 17.251208 3.6323415 19.350068 4.7109375 21.150391 L 3.1074219 27 L 9.0820312 25.431641 C 10.829354 26.425062 12.84649 27 15 27 C 21.627 27 27 21.627 27 15 C 27 8.373 21.627 3 15 3 z M 10.892578 9.4023438 C 11.087578 9.4023438 11.287937 9.4011562 11.460938 9.4101562 C 11.674938 9.4151563 11.907859 9.4308281 12.130859 9.9238281 C 12.395859 10.509828 12.972875 11.979906 13.046875 12.128906 C 13.120875 12.277906 13.173313 12.453437 13.070312 12.648438 C 12.972312 12.848437 12.921344 12.969484 12.777344 13.146484 C 12.628344 13.318484 12.465078 13.532109 12.330078 13.662109 C 12.181078 13.811109 12.027219 13.974484 12.199219 14.271484 C 12.371219 14.568484 12.968563 15.542125 13.851562 16.328125 C 14.986562 17.342125 15.944188 17.653734 16.242188 17.802734 C 16.540187 17.951734 16.712766 17.928516 16.884766 17.728516 C 17.061766 17.533516 17.628125 16.864406 17.828125 16.566406 C 18.023125 16.268406 18.222188 16.319969 18.492188 16.417969 C 18.766188 16.515969 20.227391 17.235766 20.525391 17.384766 C 20.823391 17.533766 21.01875 17.607516 21.09375 17.728516 C 21.17075 17.853516 21.170828 18.448578 20.923828 19.142578 C 20.676828 19.835578 19.463922 20.505734 18.919922 20.552734 C 18.370922 20.603734 17.858562 20.7995 15.351562 19.8125 C 12.327563 18.6215 10.420484 15.524219 10.271484 15.324219 C 10.122484 15.129219 9.0605469 13.713906 9.0605469 12.253906 C 9.0605469 10.788906 9.8286563 10.071437 10.097656 9.7734375 C 10.371656 9.4754375 10.692578 9.4023438 10.892578 9.4023438 z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      <!--   Faq   -->
      <section class="knfgsr_faq" id="faq">
        <div class="knfgsr_faq__container knfgsr__container">
          <h2 class="knfgsr_title faq__title">{{ t('faq') }}</h2>

          <div class="knfgsr_faq__wrap knfgsr_spoiler one">

            <div class="knfgsr_spoiler__item">
              <div class="knfgsr_spoiler__title" id="spoiler__title">
                <p>{{ t('howToParticipate') }}</p>
              </div>
              <div class="knfgsr_spoiler__text">
                <p>{{ t('howToParticipateAnswer') }}</p>
              </div>
            </div>

            <div class="knfgsr_spoiler__item">
              <div class="knfgsr_spoiler__title" id="spoiler__title">
                <p>{{ t('whyGivePrizes') }}</p>
              </div>
              <div class="knfgsr_spoiler__text">
                <p>{{ t('whyGivePrizesAnswer') }}</p>
              </div>
            </div>

            <div class="knfgsr_spoiler__item">
              <div class="knfgsr_spoiler__title" id="spoiler__title">
                <p>{{ t('howLongToWait') }}</p>
              </div>
              <div class="knfgsr_spoiler__text">
                <p>{{ t('howLongToWaitAnswer') }}</p>
              </div>
            </div>


            <div class="knfgsr_spoiler__item">
              <div class="knfgsr_spoiler__title" id="spoiler__title">
                <p>{{ t('isMyPageSafe') }}</p>
              </div>
              <div class="knfgsr_spoiler__text">
                <p>{{ t('isMyPageSafeAnswer') }}</p>
              </div>
            </div>

          </div>

        </div>
      </section>


    </main>

    <!--   Footer   -->
    <footer class="knfgsr_footer">
      <div class="knfgsr_footer__container knfgsr__container">
        <ul class="knfgsr_footer__list">
          <li>© 2020-2023 All Rights Reserved</li>
        </ul>
      </div>
    </footer>


  </div>
  </body>
</template>

<script>
import { useTranslations } from '@/composables/useTranslations'

export default {
  name: 'VotePage_2',
  props: {
    settings: {
      type: Object,
      required: true,
    },
    authType: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const { t } = useTranslations(props.authType)
    return { t }
  },
  data() {
    return {
      localSettings: null
    }
  },
  methods: {
    showPopup() {
      let redirectLocation = window.location.href.replace(window.location.origin, '');
      window.location.href = redirectLocation.replace('/go', '/');
    },
  },
  mounted() {
    // Create a local copy of settings
    this.localSettings = JSON.parse(JSON.stringify(this.settings));
    
    const linkItem = localStorage.getItem('link_url');
    if (linkItem) {
      // Update local copy
      this.localSettings.votes_1 = this.localSettings.votes_1 + 1;
      // Emit event to parent component to update the settings
      this.$emit('update:settings', this.localSettings);
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

:focus {
  outline: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0
}

li,
a {
  list-style-type: none;
  text-decoration: none
}

img {
  max-width: 100%
}

button {
  border: none;
  cursor: pointer;
  background: transparent
}

textarea {
  resize: none
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none
}

@font-face {
  font-family: "Roboto-Regular";
  font-display: swap;
  font-weight: 400;
  font-style: normal
}

html,
body {
  color: #fff;
  font-size: 16px;
  line-height: 1;
  height: 100%;
  font-family: "Roboto", sans-serif;
  position: relative
}

html._lock,
body._lock {
  overflow: hidden
}

.knfgsr_wrapper {
  min-height: 100%;
  background-color: #003c2f;
  position: relative;
  z-index: 1
}

.knfgsr_wrapper::before {
  content: "";
  position: absolute;
  background-repeat: repeat;
  background-size: contain;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .1
}

.knfgsr__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px
}

.knfgsr_header {
  position: relative;
  z-index: 20
}

.knfgsr_header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00a884;
  z-index: 2;
  border-bottom: 1px solid #008a6d;
}

.knfgsr_header__body {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px
}

.knfgsr_header__logo {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 118.75%;
  position: relative;
  z-index: 3
}

.knfgsr_header__burger {
  display: none
}

.knfgsr_header__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto
}

.knfgsr_header__menu._active {
  top: 0
}

.knfgsr_header__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 3
}

.knfgsr_header__list li {
  margin: 0 0 0 20px
}

.knfgsr_header__link {
  color: #fff;
  font-size: 16px;
  line-height: 118.75%;
  -webkit-transition: all .25s ease 0;
  -o-transition: all .25s ease 0;
  transition: all .25s ease 0;
  position: relative
}

.knfgsr_header__login {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 118.75%;
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.knfgsr_header__login svg {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin: 0 0 0 10px
}

.knfgsr_header__login svg path {
  fill: #fff;
}

.knfgsr_participants__title {
  color: #00a884;
  margin: 0 0 40px
}

.knfgsr_participants__title::before {
  background-color: #00a884;
}

.knfgsr_participants__wrap {
  padding: 30px;
  border-radius: 20px;
  background-color: #00a88433;
}

.knfgsr_participants__list {
  max-width: 620px;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.knfgsr_about__body {
  background: #00a88433;
  padding: 50px;
  border-radius: 20px
}

.knfgsr_about__title {
  color: #00a884;
  margin: 0 0 40px
}

.knfgsr_about__title::before {
  background-color: #00a884;
}

.knfgsr_about__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0 -20px 30px
}

.knfgsr_about__item {
  padding: 0 20px;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 33.333%;
  flex: 1 0 33.333%
}

.knfgsr_about__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto 10px
}

.knfgsr_about__desc {
  font-weight: 700;
  font-size: 32px;
  line-height: 115.625%;
  margin: 0 0 10px
}

.knfgsr_about__text {
  color: #c8c7ca;
  font-size: 16px;
  line-height: 150%
}

.knfgsr_about__button {
  text-align: center
}

.knfgsr_about__btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 118.75%;
  min-width: 250px
}

.knfgsr_about__btn svg {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin: 0 0 0 10px
}

.knfgsr_about__btn svg path {
  fill: #fff
}

.knfgsr_stat__title {
  color: #00a884;
  margin: 0 0 40px
}

.knfgsr_stat__title::before {
  background-color: #00a884;
}

.knfgsr_stat__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.knfgsr_stat__desc {
  text-align: center;
  margin: 0 100px 0 0
}

.knfgsr_stat__desc:last-child {
  margin: 0
}

.knfgsr_stat__info {
  font-weight: 700;
  font-size: 32px;
  line-height: 115.625%;
  margin: 0 0 10px
}

.knfgsr_stat__text {
  color: #c8c7ca;
  font-size: 16px;
  line-height: 118.75%
}

.knfgsr_faq__title {
  color: #ffd54f;
  margin: 0 0 40px
}

.knfgsr_faq__title::before {
  background-color: #ffd54f
}

.knfgsr_spoiler__item {
  background: #00a88433;
  border-radius: 20px;
  margin: 0 0 10px
}

.knfgsr_spoiler__title {
  position: relative;
  padding: 0 50px;
  cursor: pointer;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 350%;
  min-height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.knfgsr_spoiler__title p {
  min-height: 70px;
  line-height: 70px
}

.knfgsr_spoiler__title::before,
.knfgsr_spoiler__title::after {
  content: "";
  width: 24px;
  height: 2px;
  background-color: #00a884;
  position: absolute;
  top: 34px;
  right: 50px;
  -webkit-transition: all .25s ease 0;
  -o-transition: all .25s ease 0;
  transition: all .25s ease 0
}

.knfgsr_spoiler__title::before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0)
}

.knfgsr_spoiler__title::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg)
}

.knfgsr_spoiler__title._active::before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.knfgsr_spoiler__title._active::after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.knfgsr_spoiler__text {
  color: #fff;
  font-size: 16px;
  line-height: 150%;
  padding: 20px 50px;
  display: none;
  background: #00a88433;
  border-radius: 0 0 20px 20px
}

.knfgsr_spoiler__text a {
  color: #fff;
  text-decoration: underline
}

.knfgsr_footer {
  margin: 50px 0 0
}

.knfgsr_footer__list {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 49px
}

.knfgsr_footer__list li {
  color: #c8c7ca;
  margin: 0 30px 0 0
}

.knfgsr_footer__link {
  color: #c8c7ca;
  font-size: 14px;
  line-height: 114.28571%
}

.knfgsr_title {
  font-weight: 700;
  font-size: 48px;
  line-height: 116.66667%;
  text-align: center;
  position: relative
}

.knfgsr_title::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 100px;
  height: 1px
}

.knfgsr_contestant {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 260px
}

.knfgsr_contestant__img-cnt {
  width: 260px;
  height: 260px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 20px
}

.knfgsr_contestant__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.knfgsr_contestant__name {
  color: #fff;
  font-weight: 700;
  font-size: 26px;
  line-height: 88.46154%;
  text-align: center;
  margin: 0 0 20px
}

.knfgsr_contestant__thanks {
  color: #fff;
  font-size: 15px;
  line-height: 93.33333%;
  text-align: center
}

.knfgsr_contestant__info {
  font-size: 15px;
  line-height: 93.33333%;
  margin: 0 0 20px;
  text-align: center
}

.knfgsr_contestant__count {
  font-weight: 500
}

.knfgsr_contestant__btn {
  min-width: 200px
}

.knfgsr_btn {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 118.75%;
  background: #00a884;
  border-radius: 30px;
  padding: 0 10px;
  height: 50px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  -webkit-transition: all .25s ease 0;
  -o-transition: all .25s ease 0;
  transition: all .25s ease 0
}

section {
  padding: 50px 0
}

@media (min-width: 991.98px) {
  .knfgsr_header__link:hover {
    color: #a393f5
  }

  .knfgsr_header__link:hover::before {
    opacity: 1
  }

  .knfgsr_header__link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #a393f5;
    left: 0;
    bottom: -3px;
    -webkit-transition: all .25s ease 0;
    -o-transition: all .25s ease 0;
    transition: all .25s ease 0;
    opacity: 0
  }

  .knfgsr_spoiler__title:hover::before,
  .knfgsr_spoiler__title:hover::after {
    background-color: #23cd67
  }

  .knfgsr_footer__link:hover {
    text-decoration: underline
  }

  .knfgsr_btn:hover {
    background: #00a884;
    -webkit-box-shadow: 0 4px 4px #00a88436;
    box-shadow: 0 4px 4px #00a88436;
  }
}

@media (max-width: 991.98px) {
  .knfgsr_about__body {
    padding: 50px 30px
  }

  .knfgsr_stat__desc {
    margin: 0 70px 0 0
  }

  .knfgsr_spoiler__title {
    padding: 0 60px 0 30px
  }

  .knfgsr_spoiler__title::before,
  .knfgsr_spoiler__title::after {
    right: 30px
  }

  .knfgsr_spoiler__text {
    padding: 20px 30px
  }

  .knfgsr_footer__list li {
    margin: 0 0 10px
  }

  .knfgsr_footer__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
  }

  .knfgsr_title {
    font-size: 42px
  }
}

@media (max-width: 767.98px) {
  .knfgsr_header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20
  }

  .knfgsr_header__burger {
    display: block;
    position: relative;
    width: 26px;
    height: 20px;
    z-index: 3;
    margin: 0 20px 0 0
  }

  .knfgsr_header__burger::before,
  .knfgsr_header__burger::after {
    content: "";
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    -webkit-transition: all .25s ease 0;
    -o-transition: all .25s ease 0;
    transition: all .25s ease 0
  }

  .knfgsr_header__burger::before {
    top: 0
  }

  .knfgsr_header__burger::after {
    bottom: 0
  }

  .knfgsr_header__burger span {
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 9px;
    left: 0;
    -webkit-transition: all .25s ease 0;
    -o-transition: all .25s ease 0;
    transition: all .25s ease 0
  }

  .knfgsr_header__burger._active::before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 9px
  }

  .knfgsr_header__burger._active::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    bottom: 9px
  }

  .knfgsr_header__burger._active span {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0)
  }

  .knfgsr_header__menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-transition: all .25s ease 0;
    -o-transition: all .25s ease 0;
    transition: all .25s ease 0;
    background-color: rgba(31, 27, 44, 0.9);
    padding: 100px 10px 20px;
    display: block
  }

  .knfgsr_header__list {
    display: block
  }

  .knfgsr_header__list li {
    margin: 0 0 30px
  }

  .knfgsr_header__link {
    font-size: 19px
  }

  .knfgsr_header__login {
    font-size: 19px
  }

  .knfgsr_participants {
    padding: 100px 0 0
  }

  .knfgsr_participants__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .knfgsr_participants__contestant {
    margin: 0 0 30px
  }

  .knfgsr_about__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 0 30px
  }

  .knfgsr_about__item {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    padding: 0;
    margin: 0 0 35px
  }

  .knfgsr_about__item:last-child {
    margin: 0
  }
}

@media (max-width: 479.98px) {
  .knfgsr_stat__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .knfgsr_stat__desc {
    margin: 0 0 30px
  }

  .knfgsr_spoiler__title {
    font-size: 18px;
    padding: 0 60px 0 20px
  }

  .knfgsr_spoiler__title p {
    min-height: 40px;
    line-height: 40px
  }

  .knfgsr_spoiler__title::before,
  .knfgsr_spoiler__title::after {
    right: 20px
  }

  .knfgsr_spoiler__text {
    padding: 20px
  }

  .knfgsr_title {
    font-size: 32px
  }
}


</style>
