import { ref, computed } from 'vue'
import { translations } from '@/translations'
import storage from '@/services/storage'

export function useTranslations(propAuthType) {
  const settings = storage.get('settings')
  let authType = propAuthType || settings?.auth_type || 'default'
  
  // If authType is 'qr', use Russian translations
  const currentLocale = ref(authType === 'qr' ? 'ru' : authType)

  const t = computed(() => {
    return (key) => {
      return translations[currentLocale.value][key] || translations['default'][key]
    }
  })

  return {
    t,
    currentLocale
  }
}