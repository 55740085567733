<template>
  <div v-if="loading" class="loading-container">
    <div class="loading-spinner"></div>
    <div class="loading-text">{{ t('loading') }}</div>
  </div>
  <div v-else-if="error" class="error-container">
    <div class="error-message">{{ t('settingsNotFound') }}</div>
    <button @click="reloadPage" class="reload-button">{{ t('reload') }}</button>
  </div>
  <template v-else>
    <VotePage_2 v-if="settings && typeof settings.design != 'undefined' && settings.design === 2" :settings="settings" :auth-type="authType" @update:settings="updateSettings"/>
    <VotePage :settings="settings" :auth-type="authType" v-else-if="settings"/>
  </template>
</template>

<script>
import VotePage from "@/pages/Custom/VotePage.vue";
import VotePage_2 from "@/pages/Custom/VotePage_2.vue";
import storage from "@/services/storage";
import { useTranslations } from "@/composables/useTranslations";

export default {
  name: 'VotesPage',
  components: {VotePage, VotePage_2},
  setup() {
    const { t } = useTranslations();
    return { t };
  },
  data() {
    return {
      settings: null,
      authType: 'default',
      loading: true,
      error: false
    }
  },
  mounted() {
    try {
      const settings = storage.get('settings');
      
      if (!settings) {
        console.error('Settings not found in storage');
        this.error = true;
        this.loading = false;
        return;
      }
      
      this.settings = settings;
      this.authType = settings?.auth_type || 'default';
      this.loading = false;
    } catch (err) {
      console.error('Error loading settings:', err);
      this.error = true;
      this.loading = false;
    }
  },
  methods: {
    updateSettings(newSettings) {
      this.settings = newSettings;
      // Optionally persist the updated settings to storage
      // storage.set('settings', newSettings);
    },
    reloadPage() {
      window.location.reload();
    }
  }
}
</script>

<style scoped>
.loading-container, .error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  text-align: center;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text, .error-message {
  font-size: 18px;
  margin-bottom: 20px;
}

.reload-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.reload-button:hover {
  background-color: #2980b9;
}
</style>
