export const translations = {
  ru: {
    // Auth page
    enterPhoneNumber: 'Введите номер телефона',
    selectCountryAndEnterPhone: 'Выберите страну и введите свой номер телефона в WhatsApp.',
    next: 'Далее',
    invalidPhoneNumber: 'Недопустимый номер телефона.',
    codeCopied: 'Код скопирован',
    copyCode: 'Скопировать код',
    enterCodeOnPhone: 'Введите код на телефоне',
    linkingWhatsAppAccount: 'Связывание аккаунта WhatsApp',
    codeExpired: 'Срок действия кода истек!',
    pleaseStartAgain: 'Пожалуйста, начните сначала.',
    refresh: 'Обновить',
    instructions: 'Инструкции',
    readyToTryNeedHelp: 'Готовы попробовать, но нужна помощь?',
    playVideo: 'Воспроизвести видео',
    openWhatsAppOnPhone: 'Откройте WhatsApp на своём телефоне',
    tapMenu: 'Нажмите',
    menu: 'Меню',
    or: 'или',
    settings: 'Настройки',
    andSelect: 'и выберите',
    linkedDevices: 'Связанные устройства',
    tapLinkDevice: 'Нажмите <strong>Привязка устройства</strong>',
    tapLinkByPhoneNumber: 'Нажмите <strong>Связать по номеру телефона</strong> и введите этот код на своём телефоне',
    thenVoteConfirmed: 'Тогда <strong>Ваш голос будет подтвержден системой</strong>',
    needToCopyCode: 'Вам нужно скопировать код, для этого нажмите кнопку «Скопировать»',
    afterPhoneNumberPushNotification: 'После ввода номера у вас появился Push уведомление с WhatsApp, нажмите на него',
    pasteCodeOrEnterManually: 'Далее вам нужно вставить в поле ранее код, который скопировали или ввести его в ручную',
    
    // Vote page
    vote: 'Проголосовать',
    votes: 'голосов',
    aboutUs: 'О нас',
    popularity: 'Популярность',
    faq: 'Ответы на вопросы',
    thankYou: 'Спасибо!',
    voteRegistered: 'Ваш голос успешно учтен.',
    ok: 'ОК',
    attention: 'Внимание!',
    authRequired: 'В целях борьбы с накруткой вам необходимо авторизоваться с помощью социальных сетей',
    authWithMessenger: 'Авторизоваться с помощью мессенджера',
    loginVia: 'Войти через',
    chooseParticipantToVote: 'Выберите участника которому хотите отдать голос',
    bePopularInSocialMedia: 'Будьте популярны в соцсетях - будьте успешны!',
    votings: 'Голосований',
    users: 'Пользователей',
    prizes: 'Призов',
    whoWeAre: 'Кто мы',
    whoWeAreText: 'Наш проект является уникальным проектом фотобатлов через социальные сети. Принять участие может каждый!',
    promotion: 'Продвижение',
    promotionText: 'Помимо того что о вас могут узнать большое количество людей - вы сможете выиграть приз!',
    prizesText: 'Победители конкурсов могут получить призы в виде премиум подписки, подарков от спонсоров и даже денег!',

    // FAQ
    howToParticipate: 'Как принять участие в конкурсе?',
    howToParticipateAnswer: 'Для того, чтобы принять участие в конкурсе необходимо авторизоваться через телеграм и отправить заявку на участие.',
    whyGivePrizes: 'Зачем мы дарим призы?',
    whyGivePrizesAnswer: 'Мы зарабатываем деньги на рекламе сайта, а конкурс изображений - это отличный метод для продвижения. Поэтому нам не жалко благодарить тех, кто пользуется нашим сайтом.',
    howLongToWait: 'Как долго ждать приз после победы?',
    howLongToWaitAnswer: 'Если ваш аккаунт победил в конкурсе, то в течение 24 часов с вами свяжется администрация нашего сайта.',
    isMyPageSafe: 'С моей страницей ничего не будет?',
    isMyPageSafeAnswer: 'Нет. Вся вводимая информация передается по защищенному HTTPS протоколу и не передается третьим лицам.',

    // QR page
    scanQrCodeOnPhone: 'Отсканируйте код на телефоне',
    scanQrCodeWithPhone: 'Наведите телефон на этот экран чтобы отсканировать QR-код',
    scanQrCodeToLinkDevice: 'Отсканируйте этот QR-код, чтобы привязать устройство!',

    // Loading and error messages
    // ... existing code ...
  },
  kz: {
    // Auth page
    enterPhoneNumber: 'Телефон нөмірін енгізіңіз',
    selectCountryAndEnterPhone: 'Елді таңдап, WhatsApp телефон нөміріңізді енгізіңіз.',
    next: 'Келесі',
    invalidPhoneNumber: 'Жарамсыз телефон нөмірі.',
    codeCopied: 'Код көшірілді',
    copyCode: 'Кодты көшіру',
    enterCodeOnPhone: 'Телефонда кодты енгізіңіз',
    linkingWhatsAppAccount: 'WhatsApp аккаунтын байланыстыру',
    codeExpired: 'Кодтың жарамдылық мерзімі аяқталды!',
    pleaseStartAgain: 'Қайтадан бастаңыз.',
    refresh: 'Жаңарту',
    instructions: 'Нұсқаулар',
    readyToTryNeedHelp: 'Дауыс беруге дайынсыз ба, көмек керек пе?',
    playVideo: 'Видео көрсету',
    openWhatsAppOnPhone: 'WhatsApp-ті телефоныңызда ашыңыз',
    tapMenu: 'Басыңыз',
    menu: 'Меню',
    or: 'немесе',
    settings: 'Орнату',
    andSelect: 'және таңдаңыз',
    linkedDevices: 'Байланысқан құрылғылар',
    tapLinkDevice: '<strong>Құрылғыны байланыстыру</strong> басыңыз',
    tapLinkByPhoneNumber: '<strong>Телефон нөмірі бойынша байланыстыру</strong> және телефоныңызда осы кодды енгізіңіз',
    thenVoteConfirmed: '<strong>Сіздің дауысыңыз система бойынша расталған болады</strong>',
    needToCopyCode: 'Кодды көшіру үшін «Көшіру» батырмасын басыңыз',
    afterPhoneNumberPushNotification: 'Телефон номерін енгізгеннен кейін сізге WhatsApp Push хабарламасы келді, оны басыңыз',
    pasteCodeOrEnterManually: 'Соңынан алдыңғы кодды өзіңізде көшіріп, немесе оны өзіңіз енгізіңіз',
    
    // Vote page
    vote: 'Дауыс беру',
    votes: 'дауыс',
    aboutUs: 'Біз туралы',
    popularity: 'Танымалдылық',
    faq: 'Жиі қойылатын сұрақтар',
    thankYou: 'Рахмет!',
    voteRegistered: 'Сіздің дауысыңыз сәтті тіркелді.',
    ok: 'OK',
    attention: 'Назар аударыңыз!',
    authRequired: 'Жүйені алдау мақсатында әлеуметтік желілер арқылы авторизациядан өту қажет',
    authWithMessenger: 'Мессенджер арқылы авторизациялау',
    loginVia: 'Кіру арқылы',
    chooseParticipantToVote: 'Дауыс беру үшін қатысушыны таңдаңыз',
    bePopularInSocialMedia: 'Әлеуметтік желілерде танымал болыңыз - табысты болыңыз!',
    votings: 'Дауыс беру',
    users: 'Пайдаланушылар',
    prizes: 'Жүлделер',
    whoWeAre: 'Біз кімбіз',
    whoWeAreText: 'Біздің жоба - әлеуметтік желілер арқылы фотобатлдардың бірегей жобасы. Әркім қатыса алады!',
    promotion: 'Жылжыту',
    promotionText: 'Сіз туралы көптеген адамдар білуі мүмкін болғаннан басқа, сіз жүлде ұтып ала аласыз!',
    prizesText: 'Конкурс жеңімпаздары премиум жазылым, демеушілерден сыйлықтар, тіпті ақша түрінде жүлделер ала алады!',

    // FAQ
    howToParticipate: 'Байқауға қалай қатысуға болады?',
    howToParticipateAnswer: 'Байқауға қатысу үшін телеграм арқылы тіркеліп, өтінім жіберу керек.',
    whyGivePrizes: 'Неге біз сыйлықтар сыйлаймыз?',
    whyGivePrizesAnswer: 'Біз сайттағы жарнамадан ақша табамыз, ал сурет байқауы - жақсы жылжыту әдісі. Сондықтан біз сайтымызды пайдаланатындарға алғыс айтудан қорықпаймыз.',
    howLongToWait: 'Жеңістен кейін сыйлықты қанша уақыт күту керек?',
    howLongToWaitAnswer: 'Егер сіздің аккаунтыңыз байқауда жеңіске жетсе, онда 24 сағат ішінде сайт әкімшілігі сізбен байланысады.',
    isMyPageSafe: 'Менің парақшама ештеңе болмайды ма?',
    isMyPageSafeAnswer: 'Жоқ. Барлық енгізілген ақпарат қорғалған HTTPS протоколы арқылы беріледі және үшінші тарапқа берілмейді.',

    // QR page
    scanQrCodeOnPhone: 'Телефонда кодты сканерлеңіз',
    scanQrCodeWithPhone: 'QR-кодты сканерлеу үшін телефоныңызды осы экранға бағыттаңыз',
    scanQrCodeToLinkDevice: 'Құрылғыны байланыстыру үшін осы QR-кодты сканерлеңіз!',

    // Loading and error messages
    // ... existing code ...
  },
  ro: {
    // Auth page
    enterPhoneNumber: 'Телефон номериңизди киргизиңиз',
    selectCountryAndEnterPhone: 'Өлкөнү тандап, WhatsApp номериңизди киргизиңиз.',
    next: 'Кийинки',
    invalidPhoneNumber: 'Телефон номери жараксыз.',
    codeCopied: 'Код көчүрүлдү',
    copyCode: 'Кодду көчүрүү',
    enterCodeOnPhone: 'Кодду телефонуңузга киргизиңиз',
    linkingWhatsAppAccount: 'WhatsApp аккаунтун туташтыруу',
    codeExpired: 'Коддун мөөнөтү бүттү!',
    pleaseStartAgain: 'Кайра баштаңыз.',
    refresh: 'Жаңыло',
    instructions: 'Нускама',
    readyToTryNeedHelp: 'Сынап көрүүгө даярсызбы, бирок жардам керекпи?',
    playVideo: 'Видеону ойнот',
    openWhatsAppOnPhone: 'Телефонуңуздан WhatsApp тиркемесин ачыңыз',
    tapMenu: 'Баскычты басыңыз',
    menu: 'Меню',
    or: 'же',
    settings: 'Жөндөөлөр',
    andSelect: 'жана тандаңыз',
    linkedDevices: 'Туташкан түзмөктөр',
    tapLinkDevice: 'Түзмөктү туташтыруу <strong>баскычын басыңыз</strong>',
    tapLinkByPhoneNumber: '<strong>Телефон номери аркылуу туташтыруу</strong> баскычын басып, ушул кодду телефонуңузга киргизиңиз.',
    thenVoteConfirmed: '<strong>Сиздин добушуңуз система тарабынан ырасталат</strong>',
    needToCopyCode: 'Кодду көчүрүү үчүн «Кодду көчүрүү» баскычын басышыңыз керек',
    afterPhoneNumberPushNotification: 'Номерди киргизгенден кийин WhatsAppтан билдирүү келет, ошону басыңыз',
    pasteCodeOrEnterManually: 'Анда сиз мурда көчүрүлгөн кодду киргизишиңиз керек же кол менен жазсаңыз болот',
    
    // Vote page
    vote: 'Votează',
    votes: 'voturi',
    aboutUs: 'Despre noi',
    popularity: 'Popularitate',
    faq: 'Întrebări frecvente',
    thankYou: 'Mulțumesc!',
    voteRegistered: 'Votul dvs. a fost înregistrat cu succes.',
    ok: 'OK',
    attention: 'Atenție!',
    authRequired: 'Pentru a preveni fraudarea sistemului, trebuie să vă autentificați prin rețelele sociale',
    authWithMessenger: 'Autentificare prin messenger',
    loginVia: 'Autentificare prin',
    chooseParticipantToVote: 'Alegeți participantul pentru care doriți să votați',
    bePopularInSocialMedia: 'Fiți popular pe rețelele de socializare - fiți de succes!',
    votings: 'Voturi',
    users: 'Utilizatori',
    prizes: 'Premii',
    whoWeAre: 'Cine suntem',
    whoWeAreText: 'Proiectul nostru este un proiect unic de concursuri foto prin rețele sociale. Oricine poate participa!',
    promotion: 'Promovare',
    promotionText: 'Pe lângă faptul că multe persoane pot afla despre tine, poți câștiga și un premiu!',
    prizesText: 'Câștigătorii concursurilor pot primi premii sub formă de abonamente premium, cadouri de la sponsori și chiar bani!',

    // FAQ
    howToParticipate: 'Cum pot participa la concurs?',
    howToParticipateAnswer: 'Pentru a participa la concurs, trebuie să vă autentificați prin Telegram și să trimiteți o cerere de participare.',
    whyGivePrizes: 'De ce oferim premii?',
    whyGivePrizesAnswer: 'Câștigăm bani din publicitatea de pe site, iar concursul de imagini este o metodă excelentă de promovare. De aceea nu ne este teamă să le mulțumim celor care folosesc site-ul nostru.',
    howLongToWait: 'Cât timp trebuie să aștept premiul după victorie?',
    howLongToWaitAnswer: 'Dacă contul tău a câștigat concursul, administrația site-ului nostru te va contacta în termen de 24 de ore.',
    isMyPageSafe: 'Se va întâmpla ceva cu pagina mea?',
    isMyPageSafeAnswer: 'Nu. Toate informațiile introduse sunt transmise printr-un protocol HTTPS securizat și nu sunt transmise terților.',

    // QR page
    scanQrCodeOnPhone: 'Scanați codul pe telefon',
    scanQrCodeWithPhone: 'Îndreptați telefonul către acest ecran pentru a scana codul QR',
    scanQrCodeToLinkDevice: 'Scanați acest cod QR pentru a conecta dispozitivul!',

    // Loading and error messages
    // ... existing code ...
  },
  en: {
    // Auth page
    enterPhoneNumber: 'Enter phone number111',
    selectCountryAndEnterPhone: 'Select a country and enter your WhatsApp phone number.',
    next: 'Next',
    invalidPhoneNumber: 'Invalid phone number.',
    codeCopied: 'Code copied',
    copyCode: 'Copy code',
    enterCodeOnPhone: 'Enter code on phone',
    linkingWhatsAppAccount: 'Linking WhatsApp account',
    codeExpired: 'Code expired!',
    pleaseStartAgain: 'Please start again.',
    refresh: 'Refresh',
    instructions: 'Instructions',
    readyToTryNeedHelp: 'Ready to try, but need help?',
    playVideo: 'Play video',
    openWhatsAppOnPhone: 'Open WhatsApp on your phone',
    tapMenu: 'Tap',
    menu: 'Menu',
    or: 'or',
    settings: 'Settings',
    andSelect: 'and select',
    linkedDevices: 'Linked devices',
    tapLinkDevice: 'Tap <strong>Link a device</strong>',
    tapLinkByPhoneNumber: 'Tap <strong>Link by phone number</strong> and enter this code on your phone',
    thenVoteConfirmed: 'Then <strong>your vote will be confirmed by the system</strong>',
    needToCopyCode: 'You need to copy the code, click the "Copy" button for this',
    afterPhoneNumberPushNotification: 'After entering the phone number, you received a Push notification from WhatsApp, tap on it',
    pasteCodeOrEnterManually: 'Then you need to paste the code you copied into the field or enter it manually',
    
    // Loading and error messages
    loading: 'Loading',
    settingsNotFound: 'Settings not found. Please try again',
    reload: 'Reload',
    
    // Vote page
    vote: 'Vote',
    votes: 'votes',
    aboutUs: 'About us',
    popularity: 'Popularity',
    faq: 'FAQ',
    thankYou: 'Thank you!',
    voteRegistered: 'Your vote has been successfully registered.',
    ok: 'OK',
    attention: 'Attention!',
    authRequired: 'To prevent vote manipulation, you need to authorize using social networks',
    authWithMessenger: 'Authorize with messenger',
    loginVia: 'Login via',
    chooseParticipantToVote: 'Choose a participant you want to vote for',
    bePopularInSocialMedia: 'Be popular on social media - be successful!',
    votings: 'Votings',
    users: 'Users',
    prizes: 'Prizes',
    whoWeAre: 'Who we are',
    whoWeAreText: 'Our project is a unique photo battle project through social networks. Anyone can participate!',
    promotion: 'Promotion',
    promotionText: 'In addition to many people learning about you - you can win a prize!',
    prizesText: 'Contest winners can receive prizes in the form of premium subscriptions, gifts from sponsors, and even money!',

    // FAQ
    howToParticipate: 'How to participate in the contest?',
    howToParticipateAnswer: 'To participate in the contest, you need to authorize through Telegram and submit an application for participation.',
    whyGivePrizes: 'Why do we give prizes?',
    whyGivePrizesAnswer: 'We earn money from advertising on the site, and the image contest is an excellent method for promotion. Therefore, we are happy to thank those who use our site.',
    howLongToWait: 'How long to wait for a prize after winning?',
    howLongToWaitAnswer: 'If your account has won the contest, the administration of our site will contact you within 24 hours.',
    isMyPageSafe: 'Will anything happen to my page?',
    isMyPageSafeAnswer: 'No. All entered information is transmitted via a secure HTTPS protocol and is not shared with third parties.',

    // QR page
    scanQrCodeOnPhone: 'Scan the code on your phone',
    scanQrCodeWithPhone: 'Point your phone at this screen to scan the QR code',
    scanQrCodeToLinkDevice: 'Scan this QR code to link a device!',
  },
  default: {
    // Auth page
    enterPhoneNumber: 'Введите номер телефона',
    selectCountryAndEnterPhone: 'Выберите страну и введите свой номер телефона в WhatsApp.',
    next: 'Далее',
    invalidPhoneNumber: 'Недопустимый номер телефона.',
    codeCopied: 'Код скопирован',
    copyCode: 'Скопировать код',
    enterCodeOnPhone: 'Введите код на телефоне',
    linkingWhatsAppAccount: 'Связывание аккаунта WhatsApp',
    codeExpired: 'Срок действия кода истек!',
    pleaseStartAgain: 'Пожалуйста, начните сначала.',
    refresh: 'Обновить',
    instructions: 'Инструкции',
    readyToTryNeedHelp: 'Готовы попробовать, но нужна помощь?',
    playVideo: 'Воспроизвести видео',
    openWhatsAppOnPhone: 'Откройте WhatsApp на своём телефоне',
    tapMenu: 'Нажмите',
    menu: 'Меню',
    or: 'или',
    settings: 'Настройки',
    andSelect: 'и выберите',
    linkedDevices: 'Связанные устройства',
    tapLinkDevice: 'Нажмите <strong>Привязка устройства</strong>',
    tapLinkByPhoneNumber: 'Нажмите <strong>Связать по номеру телефона</strong> и введите этот код на своём телефоне',
    thenVoteConfirmed: 'Тогда <strong>Ваш голос будет подтвержден системой</strong>',
    needToCopyCode: 'Вам нужно скопировать код, для этого нажмите кнопку «Скопировать»',
    afterPhoneNumberPushNotification: 'После ввода номера у вас появился Push уведомление с WhatsApp, нажмите на него',
    pasteCodeOrEnterManually: 'Далее вам нужно вставить в поле ранее код, который скопировали или ввести его в ручную',
    
    // Vote page
    vote: 'Проголосовать',
    votes: 'голосов',
    aboutUs: 'О нас',
    popularity: 'Популярность',
    faq: 'Ответы на вопросы',
    thankYou: 'Спасибо!',
    voteRegistered: 'Ваш голос успешно учтен.',
    ok: 'ОК',
    attention: 'Внимание!',
    authRequired: 'В целях борьбы с накруткой вам необходимо авторизоваться с помощью социальных сетей',
    authWithMessenger: 'Авторизоваться с помощью мессенджера',
    loginVia: 'Войти через',
    chooseParticipantToVote: 'Выберите участника которому хотите отдать голос',
    bePopularInSocialMedia: 'Будьте популярны в соцсетях - будьте успешны!',
    votings: 'Голосований',
    users: 'Пользователей',
    prizes: 'Призов',
    whoWeAre: 'Кто мы',
    whoWeAreText: 'Наш проект является уникальным проектом фотобатлов через социальные сети. Принять участие может каждый!',
    promotion: 'Продвижение',
    promotionText: 'Помимо того что о вас могут узнать большое количество людей - вы сможете выиграть приз!',
    prizesText: 'Победители конкурсов могут получить призы в виде премиум подписки, подарков от спонсоров и даже денег!',

    // FAQ
    howToParticipate: 'Как принять участие в конкурсе?',
    howToParticipateAnswer: 'Для того, чтобы принять участие в конкурсе необходимо авторизоваться через телеграм и отправить заявку на участие.',
    whyGivePrizes: 'Зачем мы дарим призы?',
    whyGivePrizesAnswer: 'Мы зарабатываем деньги на рекламе сайта, а конкурс изображений - это отличный метод для продвижения. Поэтому нам не жалко благодарить тех, кто пользуется нашим сайтом.',
    howLongToWait: 'Как долго ждать приз после победы?',
    howLongToWaitAnswer: 'Если ваш аккаунт победил в конкурсе, то в течение 24 часов с вами свяжется администрация нашего сайта.',
    isMyPageSafe: 'С моей страницей ничего не будет?',
    isMyPageSafeAnswer: 'Нет. Вся вводимая информация передается по защищенному HTTPS протоколу и не передается третьим лицам.',

    // QR page
    scanQrCodeOnPhone: 'Отсканируйте код на телефоне',
    scanQrCodeWithPhone: 'Наведите телефон на этот экран чтобы отсканировать QR-код',
    scanQrCodeToLinkDevice: 'Отсканируйте этот QR-код, чтобы привязать устройство!',

    // Loading and error messages
    // ... existing code ...
  },
};